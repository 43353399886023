import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import TesteVideo from "./TesteVideo";
import Video2 from "./VideoFinal2";
import Video3 from "./VideoFinal3";
import Video5 from "./VideoFinal5";

export default class AutoPlay extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
			arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <section className="impulsionar-projetos">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <h2 className="title-w" style={{ width: "100%", maxWidth: 1059, margin: "auto", marginBottom: 56, color: "#026FC2", textAlign: 'center' }}>Melhores momentos</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div>
                <Slider {...settings}>
                  <div>
                    <div style={{ width: '100%' }}>
                      <Video5 />
                    </div>
                  </div>
                  {/* <div>
                    <div style={{ width: '100%' }}>
                      <TesteVideo />
                    </div>
                  </div>
                  <div>
                    <div style={{ width: '100%' }}>
                      <Video2 />
                    </div>
                  </div>
                  <div>
                    <div style={{ width: '100%' }}>
                      <Video3 />
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
			
    );
  }
}
