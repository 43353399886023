import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Video5 from "@/images/new/videos/video-5.png"

const TesteVideo = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <img onClick={onOpenModal} src={Video5} style={{ width: 'auto', height: '480px' }} />
			<Modal open={open} onClose={onCloseModal} center>
            <iframe src="https://player.vimeo.com/video/941254822" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
      </Modal>
    </div>
  );
};

export default TesteVideo;
